import { graphql } from "gatsby";
import React from "react";
import Layout from "~/components/Layout";

import FlexibleContent from "~/components/FlexibleContent";
import { WpPage_Page_FlexibleContent } from "~/_generated/types";
import Resources from "~/components/flexible/Resources/Resources";
import CategoryHero from "~/components/flexible/CategoryHero";
import { useSearch } from "~/hooks/useSearch";
import SearchResults from "~/components/global/SearchResults";
import useBlogPosts from "~/hooks/useBlogPosts";
import Seo from "gatsby-plugin-wpgraphql-seo";
import Pagination from "~/components/elements/Pagination";

type CategoryPageProps = {
	data: {
		wpPage: {
			page: {
				flexibleContent: WpPage_Page_FlexibleContent;
			};
		};
	};
};

export default function categoryPage(props: CategoryPageProps) {
	const {
		data: {
			wpPage,
			wp,
			wpCategory,
			siteSearchIndex,
			allWpPost: { nodes },
		},
		pageContext,
	} = props;

	const [query, setQuery] = React.useState("");

	const { allWpPost } = useBlogPosts();

	const results = useSearch(siteSearchIndex.index, query);

	const resourceResults = results.filter(({ nodeType }) => nodeType === "Post").map(({ slug }) => allWpPost.nodes.find((post) => post.slug === slug));

	return (
		<Layout wp={wp} showResourcesHeader activeSearchQuery={query} setQuery={setQuery}>
			<Seo post={wpCategory} title={wpCategory.name} />

			<div className="bg-dark-purple pt-[149px] md:pt-[224px]">
				{query.trim().length > 0 ? (
					<SearchResults results={resourceResults} query={query} />
				) : (
					<>
						<CategoryHero {...wpCategory} />
						<Resources {...wpCategory} posts={nodes} hideButton siteSearchIndex={siteSearchIndex} featured noLimit={true} addCaseStudiesGrid />
						<Pagination pageContext={pageContext} category={wpCategory?.name} />
					</>
				)}
				<FlexibleContent content={wpPage?.page?.flexibleContent} />
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query Category($id: String!, $skip: Int!) {
		wpPage(id: { eq: $id }) {
			...SEO
			...GeneratedWpPage
		}
		wp {
			...GeneratedWp
		}
		wpCategory(id: { eq: $id }) {
			...GeneratedWpCategory
		}
		allWpPost(filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }, skip: $skip, limit: 100, sort: { fields: date, order: DESC }) {
			nodes {
				uri
				title
				article {
					type
					image {
						...Image
					}
					readwatchlistenTime
					quote {
						company
						name
						text
					}
				}
			}
		}

		siteSearchIndex {
			index
		}
	}
`;
